import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import { LocalBusinessJsonLd } from 'gatsby-plugin-next-seo';

import Header from "../components/header"
import Story from "../components/story"
import Uryslygi from "../components/uryslygi"
import Sdelka  from "../components/sdelka"
import Price from "../components/price"
import Catalog from "../components/catalog"

import OtzivAudio from "../components/otzivAudio"
import OtzivVideo from "../components/otzivVideo"
import OtzivSocial from "../components/otzivSocial"
import OtzivBlank from "../components/otzivBlank"
import OtzivMap from "../components/otzivMap"
import Social from "../components/social"
import Certificate from "../components/certificate"
import Users from "../components/users"

import Blog from "../components/blog"
import Text from "../components/text"
import Map from "../components/map"

import ContactForm from "../components/contactForm"
import Call from "../components/call"
import Sale from "../components/sale"
import CallForm from "../components/callForm"
import Stado from "../components/stado"
import Policy from "../components/policy"

export const query = graphql `
	query  {
		blogs: allBlog(sort: { order: DESC, fields: datePublished }, limit: 10) {
			edges {
			  node {
				slug
				title
				description
				card
				img
				imgMini
				date
				seoTitle
				seoDescription
				author
				ava
				time
			  }
			} 
		}
	}
`

const IndexPage = ({data}) => {
	return (
		<Layout>
			<Seo title="Устные и письменные консультации | Юридическая компания | Алмаз" description="! ⚖️ «Алмаз» ➥ ⭐ Есть много сфер жизни, в которых нужно иметь юридическое образование. Если его нет — нужно обращаться к специалистам." />

			<LocalBusinessJsonLd
				type='Store'
				id='https://юридические-услуги-казань.рф/ystnye-i-pismennye-konsultacii'
				name="Устные и письменные консультации | Юридическая компания | Алмаз"
				description="! ⚖️ «Алмаз» ➥ ⭐ Есть много сфер жизни, в которых нужно иметь юридическое образование. Если его нет — нужно обращаться к специалистам."
				url='https://юридические-услуги-казань.рф'
				telephone='+78005519518'
				address={{
					streetAddress: 'Щапова, 26',
					addressLocality: 'Казань',
					addressRegion: 'Татарстан',
					postalCode: '420012',
					addressCountry: 'RU',
				}}
				geo={{
					latitude: '55.791461',
					longitude: '49.136606',
				}}
				images={[
					'https://юридические-услуги-казань.рф/img/logo.png'
				]}
			/>

			<Header title="Устные и письменные консультации" card="Сомневаетесь, что хороший юрист Вам по карману? Не знаете, как подготовиться к судебному процессу и защитить свои права? Запутались в законах и не знаете, как грамотно сформулировать претензию ?" />
			<Story />
			<Uryslygi />
			<Sdelka />
			<Price />
			<Catalog />
			<OtzivAudio />
			<OtzivVideo />
			<OtzivSocial />
			<OtzivBlank />
			<OtzivMap />
			
			<Social />
			<Certificate />
			<Users />

			<Blog data={data.blogs} />

			<Text 
				title={"Устные и письменные консультации"}
			
				html={

				`

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Есть много сфер жизни, в которых нужно иметь юридическое образование. Если его нет — нужно обращаться к специалистам. Это прекрасный выход, когда нет средств на постоянного штатного юриста или нужен ответ только на один вопрос. Способ проверить контрагентов, проверить правильность оформления документов или организации фирмы. Для всего этого есть консультация по юридическим вопросам.
				</p>

				<p class="TextText">
					<b>Устная или письменная</b>
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Оба варианта активно используются, такие консультации нужны каждому, кто хочет получить юридическую помощь. Во время общения можно разобрать гражданские, хозяйственные, налоговые, имущественные вопросы, спорные моменты по делам компании и корпоративные сложности. Обратиться можно по любому вопросу, от глобальных до незначительных.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Чтобы получить юридическую консультацию в короткий срок и с максимально полезным эффектом, нужно подготовиться к ней заранее. Если речь пойдет о договорах — нужно взять их с собой, если нужно решить гражданский спор — принести все возможные бумажные носители. Это позволяет специалисту максимально быстро разобраться в сложившейся ситуации и рассказать, какое решение будет правильным.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Устная консультация представляет собой беседу с клиентом, во время которой он может записывать нужные ему данные, задавать вопросы и совместно с консультантом искать решения. Письменная заключается в формировании отчета или юридического заключения, в котором будут подробно расписаны ответы на все интересующие частное лицо или организацию вопросы. Это будет не просто ответ на вопрос, а подкрепленный ссылками на законы и нормативные акты, логические обоснования или экспертное мнение.
				</p>

				<p class="TextText">
					<b>Кому может понадобиться письменная консультация</b>
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Устной консультации будет недостаточно, если нужно получить заключение с юридической силой.
				</p>

				<p class="TextText">
					<b>Вам потребуется подобное заключение, если:</b>
				</p>

				<p class="TextText">
				во время переговоров нужно сослаться на экспертное мнение;
				</p>

				<p class="TextText">
				результат нужно донести до человека, который не может сам явиться на консультацию — руководителю, партнеру, инвестору;
				</p>

				<p class="TextText">
				важно проработать и проконтролировать все имеющиеся риски с юридической точки зрения;
				</p>

				<p class="TextText">
				необходимо не просто узнать ответ на вопрос, но и увидеть наличие нормативной базы под ним;
				</p>

				<p class="TextText">
				нужно получить расчеты и комментарии относительно договоров, связанных с расчетами, страхованием и кредитованием;
				</p>

				<p class="TextText">
				проработать схему работы с юридической стороны — вопросы кадров, отношений с контрагентами, налогами и организацией работы.
				</p>

				<p class="TextText">
					<img alt="Агентство недвижимости Алмаз" class="uk-preserve uk-margin-small-right TextSvg" src="/svg/map.svg" width="16" height="16" uk-svg-map="true"></img>
					Такие консультации важны для физических лиц, небольших организаций и индивидуальных предпринимателей, у которых нет собственного юриста в штате или оплачивать его услуги слишком дорого. Консультации дают возможность чувствовать себя уверенными во время отстаивания собственных прав и интересов.
				</p>

				`

			}/>

			<Map />

			<ContactForm />
			<Sale />
			<Call />
			<CallForm />
			<Stado />
			<Policy />
		</Layout>
	)
}


export default IndexPage
